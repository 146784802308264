.details{
    position: relative;
    z-index: 99;
    font-family: 'Montserrat', sans-serif;
    color: #acacac;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.details a {
    text-decoration: none;
    color: #acacac;
}

.details h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #acacac;
    margin-bottom: 15px;
}

.info {
    font-size: 19px;
}

.info > div {
    margin-bottom: 3px;
}

.additionalInfo {
    max-width: 600px;
    margin-top: 10px;
    padding: 0 10px;
}

.families {
    display: flex;
    max-width: 600px;
    padding: 0 15px;
    flex-wrap: wrap;
    margin-top: 15px;
    width: 100%;
}
.families > div {
    flex: 1;
    font-size: 20px;
}

.addToCalendar {
    display: block;
    width: 300px;
    text-align: center;
    padding: 5px;
    border: 1px solid;
    margin: 20px auto;
    position: relative;
    cursor: pointer;
}

.addToCalendar > div {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    background: #f9f7f4;
    z-index: 1;
    border: 1px solid;
    border-top: none;
    top: 39px;
}

.active {
    display: flex !important;
}

.addToCalendar > div a {
    display: block;
    border-bottom: 1px solid #eaeaea;
    padding: 5px 0;
    transition: all 0.3s;
}

.addToCalendar > div a:hover {
    background: grey;
    color: #fff;
}

@media all and (max-width: 350px){
    .details h1 {
        margin-top: 25px;
    }
}