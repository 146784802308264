.clouds {
    width:100%;
    overflow:hidden;
    position: absolute;
    top: 10px;
    z-index: 1;
}

.clouds img {
    width: 50vh;
    height: auto;
    margin-right: 20vh;
}

@media all and (max-width: 320px) {
    .clouds img {
        width: 80vh;
        height: auto;
    }
}