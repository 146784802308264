.animationContainer {
    display: none;
    margin-top: 20px;
}
.lds_heart {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
}
.active {
    display: block;
}
.lds_heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #e9c0b7;
    animation: lds_heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds_heart div:after,
.lds_heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #e9c0b7;
}
.lds_heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
}
.lds_heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
}
@keyframes lds_heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}