.details {
    position: relative;
    z-index: 99;
    font-family: 'Montserrat', sans-serif;
    color: #acacac;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.details h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #acacac;
    margin-bottom: 15px;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info {
    font-size: 19px;
}

.info > div {
    margin-bottom: 3px;
}

.info label {
    cursor: pointer;
}

.additionalInfo {
    max-width: 600px;
    margin-top: 10px;
    padding: 0 10px;
}

.families {
    display: flex;
    max-width: 600px;
    padding: 0 15px;
    flex-wrap: wrap;
    margin-top: 15px;
    width: 100%;
}
.families > div {
    flex: 1;
    font-size: 20px;
}

.sendBtn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.sendBtn button {
    display: block;
    padding: 5px 10px;
    min-width: 70px;
    border: 1px solid #acacac;
    color: #acacac;
    transition: all 0.3s;
    cursor: pointer;
    box-shadow: none;
    font-size: 22px;
}

.sendBtn button:first-child {
    margin-right: 10px;
}

.sendBtn button:nth-child(2) {
    margin-right: 10px;
    background: #e07979;
    color: #fff;
    border: 1px solid #e07979;
}

.sendBtn button:hover {
    background: #acacac;
    color: #f9f7f4;
}

.sendBtn button:nth-child(2):hover {
    background: #c84e4e;
    border-color: #c84e4e;
}

.sendBtn button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.checkbox {
    position: relative;
    top: -2px;
}
.confirmedMessage {
    padding: 15px 10px;
    margin-bottom: 20px;
    border: 1px solid;
}
.successMessage {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background: #c8ffc4;
    color: #a6a6a6;
}
.errorMessage {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background: #ee6d6d;
    color: #fff;
}

@media all and (max-width: 350px){
    .details h1 {
        margin-top: 25px;
    }
}