.mountains {
    position: absolute;
    bottom: -63px;
    left: 0;
    right: 0;
    z-index: -5;
}

.mountains img {
    max-width: unset;
    width: auto;
    position: relative;
    right: 50%;
}