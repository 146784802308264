.ribbonContainer {
    width: 80%;
    max-width: 300px;
    height: 80px;
    margin: auto;
    position: absolute;
    z-index: 999;
    top: 80px;
    left: 0;
    right: 0;
}

.one > div {
    height: 50px;
}

.main {
    background: #0fadc0;
    position: relative;
    display: block;
    width: 90%;
    left: 50%;
    top: 0;
    padding: 5px;
    margin-left: -45%;
    z-index: 10;
}

.main > div {
    border: 1px dashed #fff;
    border-color: rgba(255, 255, 255, 0.5);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
}

.bk {
    background: #1199a9;
    position: absolute;
    width: 8%;
    top: 12px;
    z-index: 7;
}

.bk.l {
    left: 0;
}

.bk.r {
    right: 0;
}

.skew {
    position: absolute;
    background: #0c7582;
    width: 3%;
    top: 6px;
    z-index: 7;
}

.skew.l {
    left: 5%;
    transform: skew(00deg,20deg);
}

.skew.r {
    right: 5%;
    transform: skew(00deg,-20deg);
}

.bk.l > div {
    left: -30px;
}

.bk.r > div {
    right: -30px;
}

.arrow {
    height: 25px !important;
    position: absolute;
    z-index: 2;
    width: 0;
    height: 0;
}

.arrow.top {
    top: 0px;
    border-top: 0px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 30px solid #1199a9;
}

.arrow.bottom {
    top: 25px;
    border-top: 25px solid transparent;
    border-bottom:0px solid transparent;
    border-right: 30px solid #1199a9;
}

.r .bottom {
    border-top: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 30px solid #1199a9;
    border-right: none;
}

.r .top {
    border-bottom: 25px solid transparent;
    border-top: 0px solid transparent;
    border-left: 30px solid #1199a9;
    border-right: none;
}

@media all and (max-width: 1020px) {
    .skew.l {
        left: 5%;
        transform: skew(00deg,25deg);
    }

    .skew.r {
        right: 5%;
        transform: skew(00deg,-25deg);
    }
}

@media all and (max-width: 680px) {
    .skew.l {
        left: 5%;
        transform: skew(00deg,30deg);
    }

    .skew.r {
        right: 5%;
        transform: skew(00deg,-30deg);
    }
}

@media all and (max-width: 460px) {
    .skew.l {
        left: 5%;
        transform: skew(00deg,40deg);
    }
    .skew.r {
        right: 5%;
        transform: skew(00deg,-40deg);
    }
}

.heartContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 10px;
}

.heart {
    background-color: #e00;
    display: inline-block;
    position: relative;
    height: 100px;
    width: 100px;
    transform: rotate(-45deg);
    transform-origin: 0 right;
}
.heart:before, .heart:after {
    content: "";
    background-color: #e00;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.heart:before {
    top: -50%;
}
.heart:after {
    right: -50%;
}

.animateHeart {
    animation-duration: 3s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes pulse {
    0% {
        transform: rotate(-45deg) scale(0.75);
    }
    50% {
        transform: rotate(-45deg) scale(1);
    }
    100% {
        transform: rotate(-45deg) scale(0.75);
    }
}

.buttons {
    position: absolute;
    display: flex;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 36%;
}
.blob-btn {
    z-index: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    position: relative;
    padding: 10px 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #000;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 30px;
}
.blob-btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}
.blob-btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
}
.blob-btn:hover {
    color: #FFFFFF;
    border-radius: 30px;
}
.blob-btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
}
.blob-btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ffffff;
}
.blob-btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url("#goo");
}
.blob-btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}

.blue,
.blob-blue .blob-btn__blob {
    background-color: #0fadc0;
}
.green,
.blob-green .blob-btn__blob {
    background-color: #98b34d;
}
.orange,
.blob-orange .blob-btn__blob {
    background-color: #f8a75d;
}
@supports (filter: url("#goo")) {
    .blob-btn__blob {
        transform: translate3d(0, 150%, 0) scale(1.4);
    }
}
.blob-btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
}
.blob-btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}
.blob-btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}
.blob-btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}
.blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.7);
}

.buttons-mobile {
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 0;
    display: flex;
    width: 100%;
    background: #fff;
    box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
}

.buttons-mobile a {
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15px;
    flex-basis: 100%;
    text-align: center;
}
.buttons-mobile a:last-child {
    flex-basis: 23%;
}
.buttons-mobile a:first-child {
    flex-basis: 30%;
}
@supports (filter: url("#goo")) {
    .blob-btn:hover .blob-btn__blob {
        transform: translateZ(0) scale(1.4);
    }
}

@media all and (max-width: 767px) {
    .ribbonContainer {
        top: 60px;
    }
}

@media all and (max-width: 350px){
    .ribbonContainer {
        top: 10px;
    }
    .heart {
        display: none;
    }
}