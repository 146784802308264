.Sky {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(170,220,252,1);
    background-color: linear-gradient(203deg, rgba(170,220,252,1) 0%, rgba(0,212,255,1) 100%);
    z-index: -2;
}