.road {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background: #343434;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}

@media all and (max-width: 767px) {
    .road {
        bottom: 30px;
    }

    .wrongLink {
        bottom: 0;
        height: 80px;
    }
}