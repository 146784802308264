.blob_btn {
    z-index: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    position: relative;
    padding: 10px 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #000;
    font-size: 16px;
    background-color: transparent;
    outline: none;
    border: none;
    transition: color 0.5s;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 30px;
    text-decoration: none;
}
.blob_btn:before {
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}
.blob_btn:after {
    content: "";
    z-index: -2;
    position: absolute;
    left: 3px;
    top: 3px;
    width: 100%;
    height: 100%;
    transition: all 0.3s 0.2s;
    border-radius: 30px;
}
.blob_btn:hover {
    color: #FFFFFF;
    border-radius: 30px;
}
.blob_btn:hover:after {
    transition: all 0.3s;
    left: 0;
    top: 0;
    border-radius: 30px;
}
.blob_btn__inner {
    z-index: -1;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ffffff;
}
.blob_btn__blobs {
    position: relative;
    display: block;
    height: 100%;
    filter: url("#goo");
}
.blob_btn__blob {
    position: absolute;
    top: 2px;
    width: 25%;
    height: 100%;
    border-radius: 100%;
    transform: translate3d(0, 150%, 0) scale(1.7);
    transition: transform 0.45s;
}

.blue,
.blob_blue .blob_btn__blob {
    background-color: #0fadc0;
}
.green,
.blob_green .blob_btn__blob {
    background-color: #98b34d;
}
.orange,
.blob_orange .blob_btn__blob {
    background-color: #f8a75d;
}
@supports (filter: url("#goo")) {
    .blob_btn__blob {
        transform: translate3d(0, 150%, 0) scale(1.4);
    }
}
.blob_btn__blob:nth-child(1) {
    left: 0%;
    transition-delay: 0s;
}
.blob_btn__blob:nth-child(2) {
    left: 30%;
    transition-delay: 0.08s;
}
.blob_btn__blob:nth-child(3) {
    left: 60%;
    transition-delay: 0.16s;
}
.blob_btn__blob:nth-child(4) {
    left: 90%;
    transition-delay: 0.24s;
}
.blob_btn:hover .blob_btn__blob {
    transform: translateZ(0) scale(1.7);
}
@supports (filter: url("#goo")) {
    .blob_btn:hover .blob_btn__blob {
        transform: translateZ(0) scale(1.4);
    }
}
