.wedding_popup {
    background-color: #fff;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    left: -100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
    transition: all 0.3s;
}

.wedding_popup.active {
    left: 0;
}

.content_container {
    position: relative;
    max-width: 950px;
    width: 100%;
    margin: 0 auto 0 auto;
    height:100vh;
    background-color: #f9f7f4;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content_container hr {
    width: 100%;
    border: 0;
    border-top: 1px solid #e1e1e1;
    margin-top: 20px;
}

.top_flower {
    position: absolute;
    width: 100%;
    top: -10%;
    right: -7%;
    max-width: 800px;
    z-index: 0;
}
.bottom_flower {
    position: absolute;
    bottom: -120px;
    left: -87px;
    width: auto;
    height: 100%;
    max-height: 550px;
    z-index: 0;
}



.close_popup  {
    position: absolute;
    z-index: 999;
    top: 15px;
    left: 15px;
    display: flex;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
    padding: 5px 15px;
    background: #97b09d;
    border-radius: 50px;
}
.close_popup img {
    width: 20px;
    height: auto;
}
@media all and (max-width: 700px){
    .top_flower {
        top: -6%;
        max-width: 320px;
    }

    .bottom_flower {
        position: absolute;
        bottom: -60px;
        left: -31px;
        width: auto;
        height: 100%;
        max-height: 200px;
    }
    .details h1 {
        font-size: 25px;
    }

    .info {
        font-size: 18px;
    }

    .content_container {
        height: 100vh;
        margin-top: 0;
    }
}

@media all and (max-width: 350px){
    .top_flower {
        right: -6%;
        max-width: 190px;
    }
    .bottom_flower {
        bottom: -40px;
        left: -25px;
        width: auto;
        height: 100%;
        max-height: 130px;
    }

    .close_popup {
        top: 5px;
        left: 5px;
        font-size: 12px;
    }
    .close_popup img {
        width: 11px;
    }
}