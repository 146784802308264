.car  {
    position: absolute;
    bottom: 60px;
    max-width: 430px;
    width: 100%;
    left: 0;
    right: 0;
    margin:auto;
    padding: 0 15px;
    z-index: 2;

}


.car img.the_car {
    width: 100%;
    height: auto;
    animation: car 1s linear infinite;
}

@keyframes car {
    100% {
        transform: translateY(3px);
    }
    50% {
        transform: translateY(0px);
    }
    0% {
        transform: translateY(3px);
    }
}

.wheels {
    width: 20%;
    position: absolute;
    bottom: -20%;
    animation: wheel 1s linear infinite;
}

.speech_bubble {
    position: absolute;
    background: #fff;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    bottom: 115%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 7;
}

.speech_bubble:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    width: 0;
    height: 0;
    border: 1.406em solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    border-right: 0;
    margin-left: -0.703em;
    margin-bottom: -19px;
}

.back_wheel {
    left: 9.2%;
}

.front_wheel {
    right: 8.2%;
}

@keyframes wheel {
    100% {
        transform: rotate(360deg);
    }
}

@media all and (max-width: 350px){
    .speech_bubble {
        bottom: 105%;
        font-size: 13px;
    }
    .speech_bubble:after {
        bottom: 10px;
    }
}