.trees {
    width:100%;
    overflow:hidden;
    position: absolute;
    bottom: 35px;
    z-index: -3;
}

.trees img {
    width: 759px;
    height: auto;
    margin-right: 10vh;
    max-width: unset;
}

@media all and (max-width: 767px) {
    .trees {
        bottom: 70px;
    }
}