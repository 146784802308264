.buttons {
    position: absolute;
    display: flex;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 36%;
}

.buttons_mobile {
    position: fixed;
    justify-content: center;
    align-items: center;
    bottom: 0;
    display: flex;
    width: 100%;
    background: #fff;
    box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px -2px 5px 0px rgba(0,0,0,0.75);
}

.buttons_mobile a {
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15px;
    flex-basis: 100%;
    text-align: center;
}
.buttons_mobile a:last-child {
    flex-basis: 23%;
}
.buttons_mobile a:first-child {
    flex-basis: 30%;
}

.blue,
.blob_blue .blob_btn__blob {
    background-color: #0fadc0;
}
.green,
.blob_green .blob_btn__blob {
    background-color: #98b34d;
}
.orange,
.blob_orange .blob_btn__blob {
    background-color: #f8a75d;
}

@media all and (max-width: 767px) {
    .buttons {
        display: none;
    }
}

@media all and (min-width: 768px) {
    .buttons_mobile{
        display: none;
    }
}